@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
:root {
  --primary: #6532ba;
}

body {
  overflow-y: hidden;
}

.index {
  max-width: 1000px;
  margin: 0px auto;
}

.q-footer {
  position: absolute;
}

.text-primary {
  color: var(--primary) !important;
}

.container-no {
  width: 70%;
  margin: 0px auto;
  margin-bottom: 2rem;
}

.container {
  width: 70%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.q-field__native {
  color: black !important;
}

.q-toggle__label {
  font-size: 14px;
}

.q-breadcrumbs {
  margin-top: 1em;
  margin-left: 1em;
}

.font-12 {
  font-size: 12pt !important;
}

.font-16 {
  font-size: 16pt !important;
}

.containerScroll {
  overflow-y: auto;
  height: calc(100vh - 380px);
}

.containerScrollCart {
  overflow-y: auto;
  max-height: 265px;
}

.loader {
  font-size: 20px;
  width: 1em;
  position: absolute;
  height: 1em;
  z-index: -2001 !important;
  border-radius: 50%;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
}

.loader-container {
  height: 104vh;
  width: 100%;
  position: absolute;
  background: white;
  z-index: 10;
  top: -20px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0em -3em 0em 0.2em $primary, 2em -2em 0 0em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 -0.5em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 0em $primary;
  }
  12.5% {
    box-shadow: 0em -3em 0em 0em $primary, 2em -2em 0 0.2em $primary,
      3em 0em 0 0em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 -0.5em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 -0.5em $primary;
  }
  25% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 0em $primary,
      3em 0em 0 0.2em $primary, 2em 2em 0 0em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 -0.5em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 -0.5em $primary;
  }
  37.5% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 0em $primary, 2em 2em 0 0.2em $primary, 0em 3em 0 0em $primary,
      -2em 2em 0 -0.5em $primary, -3em 0em 0 -0.5em $primary,
      -2em -2em 0 -0.5em $primary;
  }
  50% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 0em $primary,
      0em 3em 0 0.2em $primary, -2em 2em 0 0em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 -0.5em $primary;
  }
  62.5% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 0em $primary, -2em 2em 0 0.2em $primary, -3em 0em 0 0em $primary,
      -2em -2em 0 -0.5em $primary;
  }
  75% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 0em $primary,
      -3em 0em 0 0.2em $primary, -2em -2em 0 0em $primary;
  }
  87.5% {
    box-shadow: 0em -3em 0em 0em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 0em $primary,
      -3em 0em 0 0em $primary, -2em -2em 0 0.2em $primary;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0em -3em 0em 0.2em $primary, 2em -2em 0 0em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 -0.5em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 0em $primary;
  }
  12.5% {
    box-shadow: 0em -3em 0em 0em $primary, 2em -2em 0 0.2em $primary,
      3em 0em 0 0em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 -0.5em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 -0.5em $primary;
  }
  25% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 0em $primary,
      3em 0em 0 0.2em $primary, 2em 2em 0 0em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 -0.5em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 -0.5em $primary;
  }
  37.5% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 0em $primary, 2em 2em 0 0.2em $primary, 0em 3em 0 0em $primary,
      -2em 2em 0 -0.5em $primary, -3em 0em 0 -0.5em $primary,
      -2em -2em 0 -0.5em $primary;
  }
  50% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 0em $primary,
      0em 3em 0 0.2em $primary, -2em 2em 0 0em $primary,
      -3em 0em 0 -0.5em $primary, -2em -2em 0 -0.5em $primary;
  }
  62.5% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 0em $primary, -2em 2em 0 0.2em $primary, -3em 0em 0 0em $primary,
      -2em -2em 0 -0.5em $primary;
  }
  75% {
    box-shadow: 0em -3em 0em -0.5em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 0em $primary,
      -3em 0em 0 0.2em $primary, -2em -2em 0 0em $primary;
  }
  87.5% {
    box-shadow: 0em -3em 0em 0em $primary, 2em -2em 0 -0.5em $primary,
      3em 0em 0 -0.5em $primary, 2em 2em 0 -0.5em $primary,
      0em 3em 0 -0.5em $primary, -2em 2em 0 0em $primary,
      -3em 0em 0 0em $primary, -2em -2em 0 0.2em $primary;
  }
}

.q-header {
  height: 100px !important;
  padding: 12px 5px;
  max-width: 1000px;
  margin: 0px auto;
}

.logo {
  max-width: 120px;
  height: 60px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.banner {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  object-fit: cover;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.category {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.border-image {
  border: 4px dashed;
}

.categories-scroll {
  height: 180px;
  width: 100%;
}

.hide-scrollbar {
  scrollbar-width: unset;
  -ms-overflow-style: none;
}

.category-section {
  max-width: auto;
}

.font-10 {
  font-size: 10pt;
}

.font-14 {
  font-size: 14pt;
}

.round-10 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.colorG {
  color: #4d4d4d;
}

.product-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  image-rendering: optimizeQuality;
}

.rallado {
  text-decoration: line-through;
}

.no-product {
  height: 320px;
  width: 100%;
}

.carouselCustom {
  height: calc(100vh - 140px);
}

.product-container {
  width: 80%;
  margin: 0px auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.productQuantity:focus {
  outline: none;
  border: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.textGr {
  color: #343a3f;
}

.animated {
  animation: translationY 0.5s;
  -webkit-animation: translationY 0.5s;
}

.expandedAuto {
  animation: translationY 0.5s infinite;
  -webkit-animation: translationY 0.5s infinite;
}

.noCatalog {
  width: 70%;
  margin: 0px auto;
}

@keyframes translationY {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes translationY {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 1061px) {
  .carouselCustom {
    height: calc(100vh - 10 0px);
  }

  .scrollProduct {
    height: calc(100vh - 400px) !important;
  }
}

.categoryChip {
  border: 1px solid #d3cece;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  font-weight: 600;
  width: auto;
}

.containerScroll img {
  width: 100%;
}

@media screen and (max-width: 1060px) {
  .product-details {
    height: calc(100vh - 50%) !important;
  }

  .product-container {
    width: 100%;
    margin: 0px auto;
  }
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 1060px */
  .container {
    width: 90%;
  }

  .banner {
    max-height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
  }
}

@media screen and (max-width: 1400px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 1400px */
  .no-product {
    height: 280px;
    width: 100%;
  }
}

@media screen and (max-width: 1250px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 1250px */
  .no-product {
    height: 260px;
    width: 100%;
  }
}

@media screen and (max-width: 1150px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 1250px */
  .no-product {
    height: 240px;
    width: 100%;
  }
}

@media screen and (max-width: 1050px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 1050px */
  .no-product {
    height: 210px;
    width: 100%;
  }

  .mobileCarousel {
    height: 340px !important;
  }

  .mobileCarousel .q-carousel__slide {
    min-height: 100%;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat !important;
  }
}

@media screen and (max-width: 990px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 990px */
  .no-product {
    height: 320px;
    width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .noFound {
    width: 100%;
    height: 300px;
  }
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 720px */
  .no-product {
    height: 280px;
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 550px */
  .no-product {
    height: 220px;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  /* Aquí puedes agregar tus estilos CSS para pantallas con un ancho máximo de 550px */
  .no-product {
    height: 160px;
    width: 100%;
  }
}
